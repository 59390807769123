import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><inlineCode parentName="p">{`useOpenAndCloseFocus`}</inlineCode>{` is a utility Hook that manages focusing an element when a component is first mounted, and returns focus to an element on the page when that component unmounts.`}</p>
    <p>{`If no ref is passed to `}<inlineCode parentName="p">{`initialFocusRef`}</inlineCode>{`, the hook focuses the first focusable element inside of the container.`}</p>
    <p>{`If `}<inlineCode parentName="p">{`preventFocusOnOpen`}</inlineCode>{` prop is passed, then no focus will be applied when component mounts, even if `}<inlineCode parentName="p">{`initialFocusRef`}</inlineCode>{` prop is included. Only initial focus is prevented; focus will still be returned to `}<inlineCode parentName="p">{`returnFocusRef`}</inlineCode>{` when component unmounts.`}</p>
    <h3>{`Usage`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript",
        "metastring": "live noinline",
        "live": true,
        "noinline": true
      }}>{`const Overlay = ({returnFocusRef, initialFocusRef, preventFocusOnOpen, children}) => {
  const containerRef = React.useRef(null)
  useOpenAndCloseFocus({containerRef, returnFocusRef, initialFocusRef, preventFocusOnOpen})
  return (
    <Box height="200px" ref={containerRef}>
      {children}
    </Box>
  )
}

function Component() {
  const returnFocusRef = React.useRef(null)
  const initialFocusRef = React.useRef(null)
  const [isOpen, setIsOpen] = React.useState(false)
  return (
    <Box sx={{'*': {':focus': {backgroundColor: 'red.5'}}}}>
      <Button ref={returnFocusRef} onClick={() => setIsOpen(!isOpen)}>
        toggle
      </Button>
      {isOpen && (
        <Overlay returnFocusRef={returnFocusRef} initialFocusRef={initialFocusRef} preventFocusOnOpen={true}>
          <Button>Button One</Button>
          <Button ref={initialFocusRef}>Button Two</Button>
        </Overlay>
      )}
    </Box>
  )
}

render(<Component />)
`}</code></pre>
    <h4>{`useOpenAndCloseFocus settings`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`initialFocusRef`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`React.RefObject<HTMLElement>`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Optional. The element to focus when the container is mounted on the page.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`returnFocusRef`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`React.RefObject<HTMLElement>`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Required. The element to focus when the container is unmounted.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`containerRef`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`React.RefObject<HTMLElement>`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Required. A ref for the containing element.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`preventFocusOnOpen`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`React.RefObject<HTMLElement>`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Optional. When true, prevents focus when container is mounted.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      